<template>
  <moe-page title="宠物粮食管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="cerealSearch">
      <el-form-item label="宠物粮食品牌">
        <el-input v-model.trim="cerealParams.brand" placeholder="请输入宠物粮食品牌" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="宠物类型">
        <moe-select v-model="cerealParams.pesType" type="petType" placeholder="选择宠物类型" />
      </el-form-item>
    </moe-inquire>

    <!-- 宠物粮食信息 -->
    <moe-table ref="cerealTable" url="/petsFood/pageList" :params="cerealParams" row-key="id" :number-show="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="danger" icon="el-icon-delete" @click="batchDelete">批量删除</el-button>

        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/pet-cereal/add')">粮食添加</el-button>
      </template>
      <el-table-column type="selection" reserve-selection label="ID" width="60" fixed="left" />
      <el-table-column prop="brand" label="宠物粮食品牌" min-width="150" />
      <el-table-column prop="cereal" label="宠物类型" min-width="100">
        <template slot-scope="{ row }">
          {{ $moe_format.getPetType(row.pesType) }}
        </template>
      </el-table-column>
      <el-table-column prop="state" label="粮食数量" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.names.length }}
        </template>
      </el-table-column>
      <el-table-column prop="cereal" label="图片" min-width="100">
        <template slot-scope="{ row }">
          <moe-image :src="row.img" width="50px" height="50px"></moe-image>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/pet-cereal/amend?id=${row.id}`)">修改</el-button>
          <el-button type="success" size="mini" icon="el-icon-view" @click="$router.push(`/pet-cereal/detail?id=${row.id}`)">详情</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'PetCerealList',
  data() {
    //宠物粮食筛选信息
    let cerealParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      brand: '', //品牌
      pesType: '', //宠物类型
    };

    return {
      cerealParams, //宠物粮食筛选信息
    };
  },
  methods: {
    /**
     * 批量删除
     **/
    batchDelete() {
      //获取已选数据ID
      let ids = this.$refs.cerealTable.getSelectId();
      if (ids.length === 0) {
        this.$moe_msg.warning('请选择数据 !');
        return;
      }

      this.$moe_layer.confirm('是否要删除选中的宠物粮食信息 ?', () => {
        // 调用删除宠物粮食API
        this.$moe_api.PET_API.petCerealDelete({ ids: ids.join(',') }).then(
          (data) => {
            if (data.code == 200) {
              this.$moe_msg.success('删除成功');

              //刷新宠物粮食表格
              this.$refs.cerealTable.clearLoadData();
            } else {
              this.$moe_msg.error(data.message);
            }
          }
        );
      });
    },

    /**
     * 宠物粮食搜索
     **/
    cerealSearch(isSearch) {
      if (!isSearch) {
        this.cerealParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          brand: '', //品牌
          pesType: '', //宠物类型
        };
      }

      //刷新宠物粮食表格
      this.$refs['cerealTable'].clearLoadData();
    },
  },
};
</script>